/**
* maco-magsael-sc-589: Calendar 
* maco-magsael-sc-586: Print: change text color to black
* olain-tarcina-sc-611: removed powered by Novu text
*/

.header-color {
  color: '#ff5722!important';
}

/* Modification start - 15.03.2024 - olain-tarcina-sc-611 */
.nc-footer {
  display: none !important;
}
/* Modification end - 15.03.2024 - olain-tarcina-sc-611 */

@media print {
  /*@page 
    size: 8.5in 11in;
    margin: 2cm;
   */
  body * {
    background-color: #fff !important;
    /* // modification start - 02.02.2024 - maco-magsael-sc-586 */
    color: #1a3353 !important;
    /* // modification end - 02.02.2024 - maco-magsael-sc-586 */
  }
  /* // modification start - 02.19.2024 - maco-magsael-sc-589 */
  .fc-button {
    display: none !important;
  }
  .fc-toolbar-title {
    color: #1a3353 !important;
  }
  .fc-event-title {
    color: #1a3353 !important; /* Change font color for events */
  }
}

.fc-event-title.fc-sticky {
  color: #1a3353; /* Change font color for events */
}
/* // modification end - 02.19.2024 - maco-magsael-sc-589 */
